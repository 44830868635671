var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-in"},[_c('a-row',{attrs:{"type":"flex","gutter":[24, 24],"justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"col-form",attrs:{"span":24,"md":12,"lg":{ span: 12, offset: 0 },"xl":{ span: 6, offset: 2 }}},[_c('h1',{staticClass:"mb-15"},[_vm._v("Sign In")]),_c('h5',{staticClass:"font-regular text-muted"},[_vm._v(" Enter your email and password to sign in ")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Email","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  { required: true, message: 'Please input your email!' } ],
              } ]),expression:"[\n              'email',\n              {\n                rules: [\n                  { required: true, message: 'Please input your email!' },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5",attrs:{"label":"Password","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  { required: true, message: 'Please input your password!' } ],
              } ]),expression:"[\n              'password',\n              {\n                rules: [\n                  { required: true, message: 'Please input your password!' },\n                ],\n              },\n            ]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN IN ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }